import {
  Button,
  Card,
  CardGrid,
  Cell,
  Div,
  Group,
  HorizontalCell,
  Panel,
  PanelHeader,
  Text,
  Title,
} from "@vkontakte/vkui";
import { useLocation, useNavigate } from "react-router-dom";

export const QuizPassedPanel = () => {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <Panel id="QuizPassedPanel">
      <PanelHeader>Тест формирован</PanelHeader>
      <Group
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Title level="2" style={{ textAlign: "center" }}>
          Задания на основании теста сформированы
        </Title>
        <CardGrid size="l">
          <Cell
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              style={{}}
              size="l"
              stretched
              onClick={
                // () => navigate("/")
                () => window.location.reload()
                //   (window.top.location.href = "https://vk.com/app8013570")
              }
            >
              Приступить
            </Button>
          </Cell>
        </CardGrid>
      </Group>
    </Panel>
  );
};
