import { Icon56UsersOutline } from "@vkontakte/icons";
import {
  Button,
  Div,
  FormItem,
  FormLayout,
  Group,
  Input,
  Placeholder,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  Text,
  Title,
} from "@vkontakte/vkui";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { config } from "../config";
import { useTimer } from "../hooks/useTimer";
import { StoreContext } from "../store/Store";
import { StringHelper } from "../utils/StringHelper";

export const ProlongPanel = ({ setActivePanel, id, vkData }) => {
  //   const [phone, setPhone] = useState(dbData.phone);
  const [phone, setPhone] = useState("+77777777777");
  const [allowed, setAllowed] = useState(true);
  const [month, setMonth] = useState(1);
  const [tarif, setTarif] = useState(2000);
  const [resultSum, setResultSum] = useState(tarif);
  // const [timeInMilliseconds, setTimeInMilliseconds] = useState(Date.now());
  const navigate = useNavigate();
  const location = useLocation();
  const user = useContext(StoreContext).users;

  //Func
  const monthHandler = (monthCount) => {
    let discount = 0;
    if (monthCount === 3) discount = (10 / 100) * tarif * monthCount;
    if (monthCount === 6) discount = (15 / 100) * tarif * monthCount;
    if (monthCount === 12) discount = (20 / 100) * tarif * monthCount;
    setMonth(monthCount);
    setResultSum(monthCount * tarif - discount);
  };

  let onPaymentButtonText = "";
  if (user.data?.active === true || user.data?.active === false) {
    onPaymentButtonText = `Продлить за ${resultSum} ₽ на ${month} мес.`;
  } else {
    onPaymentButtonText = `Купить за ${resultSum} ₽ на ${month} мес.`;
  }

  const discountText =
    month > 1 &&
    `Стоимость ${month} месяцев стоит ${
      month * tarif
    } рублей, но с учетом скидки в ${
      ((tarif * month - resultSum) / (tarif * month)) * 100
    }% вам покупка обойдется в ${resultSum} руб.`;

  const goMain = () => {
    console.log(window.location.pathname);
    console.log(location);
    if (location.pathname === "/") {
      return setActivePanel("AccountPanel");
    }
    if (location.pathname === "/payment") {
      return navigate("/account");
    }
    if (location.pathname === "/account") {
      return setActivePanel("AccountPanel");
    }
  };

  const onPaymentHandler = async () => {
    await axios
      .post(
        config.api.paymentLink,
        {
          phone: +phone.replace(/\D+/g, ""),
          vk_user_id: user.data.id ?? user.data.vk_user_id,
          first_name: user.data.first_name,
          last_name: user.data.last_name,
          group_id: "dbData.group_id",
          tariff: "club3_" + "tren",
          fio: user.data.first_name + " " + user.data.last_name,
          club: "dbData.is_cluber",
          month: month,
          price: resultSum,
          active: user.data?.active === true ? true : false,
        },
        { headers: { "Content-Type": "application/json" } }
      )

      // .then((res) => (window.top.location.href = "https://vk.com/app8013570"))

      .then((res) => (window.top.location.href = res.data.paymentLink))
      .catch((e) => console.log(e));
  };

  const { days, hours, minutes, seconds } = useTimer(2);

  if (allowed) {
    return (
      <Panel id="ProlongPanel">
        {user.data?.active === true || user.data?.active === false ? (
          <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
            Продление тренажера
          </PanelHeader>
        ) : (
          <PanelHeader>Купить тренажер</PanelHeader>
        )}

        <Group>
          <FormLayout>
            <FormItem
              top="Телефон"
              status={phone.replace(/\D+/g, "").length > 10 ? "valid" : "error"}
              bottom={
                phone.replace(/\D+/g, "").length > 10
                  ? ""
                  : "Пожалуйста, введите свой телефон"
              }
            >
              <Input
                type="phone"
                name="phone"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </FormItem>

            {!user.data._id &&
              (+days > 0 || +hours > 0 || +minutes > 0 || +seconds > 0) && (
                <Div style={{ textAlign: "center" }}>
                  <h1>Ваша скидка сгорает через:</h1>
                  <span>
                    <Title level="1" style={{ fontSize: "36px" }}>
                      {days} : {hours} : {minutes} : {seconds}
                    </Title>
                    <Text style={{ marginTop: "10px", wordSpacing: "35px" }}>
                      дни час мин сек
                    </Text>
                  </span>
                </Div>
              )}
            <FormItem>
              <Placeholder
                // icon={<Icon56UsersOutline />}
                // header={"text.header"}
                action={
                  <>
                    <Div className="month_wrap">
                      <Button
                        size="m"
                        mode={month === 1 ? "commerce" : "outline"}
                        // onClick={() => setMonth(1)}
                        onClick={() => monthHandler(1)}
                      >
                        1 мес.
                      </Button>
                      <Button
                        size="m"
                        mode={month === 3 ? "commerce" : "outline"}
                        onClick={() => monthHandler(3)}
                      >
                        3 мес.
                      </Button>
                      <Button
                        size="m"
                        mode={month === 6 ? "commerce" : "outline"}
                        onClick={() => monthHandler(6)}
                      >
                        6 мес.
                      </Button>
                      <Button
                        size="m"
                        mode={month === 12 ? "commerce" : "outline"}
                        onClick={() => monthHandler(12)}
                      >
                        12 мес.
                      </Button>
                    </Div>
                    <Text>{discountText}</Text>
                    <Button onClick={onPaymentHandler} size="m">
                      {onPaymentButtonText}
                    </Button>
                  </>
                }
              >
                {/* {"text.content"} */}
              </Placeholder>
            </FormItem>
          </FormLayout>
        </Group>
      </Panel>
    );
  } else {
    return (
      <Panel id={id}>
        <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
          Продление тренажера
        </PanelHeader>
        <Placeholder
          icon={<Icon56UsersOutline />}
          header="У вас закрыты сообщения с группой. Если не открыть сообщения, то вы не получите уведомление об успешной оплате"
          action={
            <Button onClick={"handlerOpen"} size="m">
              Открыть сообщения
            </Button>
          }
        >
          Попробуем еще разок?
        </Placeholder>
      </Panel>
    );
  }
};
