import { Button } from "@vkontakte/vkui";

export const ExerciseListElement = ({
  exerciseTitle,
  exerciseData,
  setActivePanel,
  setActiveExercise,
  user,
}) => {
  return (
    <Button
      mode={
        (user.data.current_exercise._id === exerciseData._id &&
          user.data.current_exercise_progress !== 100 &&
          "destructive") ||
        (user.data.current_exercise._id !== exerciseData._id &&
          user.data.passed_exercises
            .map((el) => el._id.toString())
            .includes(exerciseData._id.toString()) &&
          "commerce") ||
        // : "commerce"
        (user.data.current_exercise._id !== exerciseData._id &&
          !user.data.passed_exercises
            .map((el) => el._id.toString())
            .includes(exerciseData._id.toString()) &&
          "secondary")
      }
      disabled={
        user.data.current_exercise._id !== exerciseData._id &&
        !user.data.passed_exercises
          .map((el) => el._id.toString())
          .includes(exerciseData._id.toString())
      }
      style={{ margin: "5px 20px" }}
      onClick={() => {
        setActiveExercise(exerciseData);
        if (
          exerciseData?.progress < 100 &&
          exerciseData._id === user.data.current_exercise._id
        ) {
          setActivePanel("CurrentExercisePanel");
        } else {
          setActivePanel("OneExercisePanel");
        }
      }}
      size="l"
    >
      {exerciseTitle}
    </Button>
  );
};
