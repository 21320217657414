import { View } from "@vkontakte/vkui";
import { useState } from "react";
import { QuizPassedPanel } from "../../Panels/QuizPassedPanel";

export const QuizPassedView = () => {
  const [activePanel, setActivePanel] = useState("StartingQuizPanel");
  return (
    <View activePanel={activePanel}>
      <QuizPassedPanel id={activePanel} setActivePanel={setActivePanel} />
    </View>
  );
};
