import bridge from "@vkontakte/vk-bridge";
import { AdaptivityProvider, ConfigProvider } from "@vkontakte/vkui";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { StoreProvider } from "./store/Store";
// import reportWebVitals from "./reportWebVitals";

bridge.send("VKWebAppInit", {});

ReactDOM.render(
  <React.StrictMode>
    <Router hashType="noslash">
      <ConfigProvider>
        <AdaptivityProvider>
          <StoreProvider>
            {/* <Router hashType="noslash"> */}
            <App />
          </StoreProvider>
        </AdaptivityProvider>
      </ConfigProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
