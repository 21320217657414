export const config = {
  vkStyle: {
    avatarSize: 64,
  },
  api: {
    paymentLink: "https://yoga.stn21f.ru/api/v1/payment",
    postStartingTestLink: "https://yoga.stn21f.ru/api/v1/starting-quiz",
    userDBdataLink: "https://yoga.stn21f.ru/api/v1/user",
    getChatLinkUrl: "https://yoga.stn21f.ru/api/v1/user/chat",
    getLastPostUrl: "https://yoga.stn21f.ru/api/v1/last-post",
  },
  VK_GROUP_ID: 36627892,
  APP_ADMINS_ARRAY: [2003432, 27793512, 265527734, 52778141],
};
