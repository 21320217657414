import { Icon24Dismiss } from "@vkontakte/icons";
import {
  ModalCard,
  ModalPageHeader,
  ModalRoot,
  PanelHeaderButton,
  View,
} from "@vkontakte/vkui";
import { useState } from "react";
import YouTube from "react-youtube";
import { useContext } from "react/cjs/react.development";
import { AccountPanel } from "../../Panels/Account";
import { CurrentExercisePanel } from "../../Panels/CurrentExercise";
import { ExerciseListPanel } from "../../Panels/ExerciseList";
import { OneExercisePanel } from "../../Panels/OneExercise";
import { ProlongPanel } from "../../Panels/Prolong";
import { StoreContext } from "../../store/Store";

export const AccountView = ({ exercises_pool, current_exercise, user }) => {
  console.log("exercises_pool:", exercises_pool);
  console.log("current_exercise:", current_exercise);

  const [activePanel, setActivePanel] = useState("AccountPanel");
  const [activeModal, setActiveModal] = useState(null);
  const [activeExercise, setActiveExercise] = useState("");

  const onCloseHandler = () => {
    setActiveModal(null);
  };

  const { innerWidth: width } = window;

  const opts = {
    height: width >= 440 ? 408 * 0.6 : (width - 32) * 0.6,
    width: width >= 440 ? 408 : width - 32,
  };

  const onHelp1 = () => {
    setActiveModal("help1");
  };

  const modal = (
    <ModalRoot activeModal={activeModal}>
      <ModalCard
        id="help1"
        onClose={onCloseHandler}
        sizeX="200px"
        sizeY="200px"
      >
        <ModalPageHeader
          right={
            <PanelHeaderButton onClick={onCloseHandler}>
              <Icon24Dismiss />
            </PanelHeaderButton>
          }
        >
          Помощь
        </ModalPageHeader>
        <YouTube videoId="ht3g-mjEnWo" opts={opts} />
        ht3g-mjEnWo
      </ModalCard>
    </ModalRoot>
  );

  return (
    <View activePanel={activePanel} modal={modal}>
      <AccountPanel
        id="AccountPanel"
        onHelp1={onHelp1}
        setActivePanel={setActivePanel}
        setActiveExercise={setActiveExercise}
      />
      <ProlongPanel id="ProlongPanel" setActivePanel={setActivePanel} />
      <ExerciseListPanel
        id="ExerciseListPanel"
        setActivePanel={setActivePanel}
        exercises_pool={exercises_pool}
        setActiveExercise={setActiveExercise}
        user={user}
      />
      <OneExercisePanel
        id="OneExercisePanel"
        setActivePanel={setActivePanel}
        setActiveExercise={setActiveExercise}
        data={activeExercise}
      />
      <CurrentExercisePanel
        id="CurrentExercisePanel"
        setActivePanel={setActivePanel}
        data={activeExercise}
        user={user}
      />
    </View>
  );
};
