import {
  Button,
  Card,
  CardGrid,
  Div,
  Group,
  Link,
  Panel,
  PanelHeader,
  PanelHeaderBack,
  PanelHeaderButton,
  Text,
} from "@vkontakte/vkui";

export const AdminPanel = ({ home_work_array, userData }) => {
  const toCommentHandler = (link) => {
    window.top.location.href = link;
  };
  const goMain = () => {
    window.top.location.href = "https://vk.com/app8013570";
    // return setActivePanel("AccountPanel");
  };
  return (
    <Panel id="AdminPanel">
      <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
        Administration
      </PanelHeader>
      <Group>
        {home_work_array.map((el, idx) => (
          <CardGrid size="l" key={idx}>
            <Card mode="shadow">
              <Div>
                <Div>{new Date(el.date).toLocaleString()}</Div>
                <Div>{el.text}</Div>

                <Div>
                  <Button
                    size="m"
                    mode="commerce"
                    onClick={() => toCommentHandler(el.link)}
                  >
                    К комменткрию
                  </Button>
                </Div>
              </Div>
            </Card>
          </CardGrid>
        ))}
      </Group>
    </Panel>
  );
};
