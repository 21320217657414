import { createContext } from "react";
import { useUserData } from "./state/userState";

export const StoreContext = createContext();

export const StoreProvider = ({ context, children }) => {
  const useCreateStore = () => {
    const store = {
      // Add slices
      users: useUserData(),
    };
    return store;
  };
  const store = useCreateStore();
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

// export const useStore = () => {
//   const store = {
//     users: useUserData(),
//   };
//   return store;
// };
