import { View } from "@vkontakte/vkui";
import { useState } from "react";
import { ProlongPanel } from "../../Panels/Prolong";

export const PaymentView = (user) => {
  const [activePanel, setActivePanel] = useState("ProlongPanel");

  return (
    <View activePanel={activePanel}>
      <ProlongPanel id={activePanel} setActivePanel={setActivePanel} />
    </View>
  );
};
