import { View } from "@vkontakte/vkui";
import { useState } from "react";
import { StartingQuizPanel } from "../../Panels/StartingQuiz";

export const StartingQuizView = () => {
  const [activePanel, setActivePanel] = useState("StartingQuizPanel");

  return (
    <View activePanel={activePanel}>
      <StartingQuizPanel id={activePanel} setActivePanel={setActivePanel} />
    </View>
  );
};
