import axios from "axios";
import { useEffect, useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import { config } from "../../config";

export const useUserData = () => {
  const [data, setData] = useState({});

  const setCurrentUserData = (userDataFromVK) => {
    setData((prev) => ({ ...prev, ...userDataFromVK }));
  };

  useEffect(() => {
    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === "VKWebAppUpdateConfig") {
        const schemeAttribute = document.createAttribute("scheme");
        schemeAttribute.value = data.scheme ? data.scheme : "client_light";
        // schemeAttribute.value = "client_light";
        document.body.attributes.setNamedItem(schemeAttribute);
      }
    });

    async function fetchData() {
      let isGroupMessagesAllowed = await bridge.send(
        "VKWebAppAllowMessagesFromGroup",
        {
          group_id: config.VK_GROUP_ID,
          // key: "dBuBKe1kFcdemzB",
        }
      );
      console.log("isGroupMessagesAllowed", isGroupMessagesAllowed);
      const user = await bridge.send("VKWebAppGetUserInfo");
      const { data } = await axios.get(config.api.userDBdataLink, {
        // const { data } = await axios.get("https://yoga.stn21f.ru/api/v1/user", {
        params: {
          vk_user_id: user.id,
        },
      });
      if (!data?.vk_user_id) {
        setData(user);
      } else {
        setData(data);
      }
    }
    fetchData();
  }, []);

  return { data, setCurrentUserData };
};
