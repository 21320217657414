import { Text, View } from "@vkontakte/vkui";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../config";
import { AdminPanel } from "../../Panels/Admin";

export const AdminView = (user) => {
  const [activePanel, setActivePanel] = useState("AdminPanel");
  const [userData, setUserData] = useState({});
  const [userHomeWork, setUserHomeWork] = useState([]);
  const params = useParams();
  console.log(params);

  useEffect(() => {
    async function fetch_userdata_from_db() {
      const { data } =
        (await axios.get(config.api.userDBdataLink, {
          params: { vk_user_id: params.id },
        })) ?? "";
      if (!data) {
        return console.log("User not found");
      }
      setUserData({
        first_name: data.first_name,
        last_name: data.last_name,
        vk_user_id: data.vk_user_id,
      });
      console.log(data);
      setUserHomeWork(data.home_work_array);
    }
    fetch_userdata_from_db();
  }, [params.id]);
  return (
    <View activePanel={activePanel}>
      <AdminPanel
        id={activePanel}
        home_work_array={userHomeWork}
        setActivePanel={setActivePanel}
        userData={userData}
      />
    </View>
  );
};
