import { useEffect, useState } from "react";

export const useTimer = (deltaDays) => {
  let futureTimeInMilliseconds = "";

  if (localStorage.getItem("expirationDateInMilliseconds") === null) {
    const currentTime = new Date();
    futureTimeInMilliseconds = currentTime.setDate(
      currentTime.getDate() + deltaDays
    );
    localStorage.setItem(
      "expirationDateInMilliseconds",
      futureTimeInMilliseconds
    );
  } else {
    futureTimeInMilliseconds = +localStorage.getItem(
      "expirationDateInMilliseconds"
    );
  }

  let deltaTime = +futureTimeInMilliseconds - Date.now();
  const [milliseconds, setMilliseconds] = useState(deltaTime);
  const [days, setDays] = useState();
  const [hours, sethours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  const getRemainingTime = (timeInMilliseconds) => {
    let remDays = timeInMilliseconds / 3600000 / 24;
    let remHours = timeInMilliseconds / 3600000;
    let remSeconds = timeInMilliseconds / 1000;
    let remMinutes = timeInMilliseconds / 60000;

    return { remHours, remSeconds, remDays, remMinutes };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setMilliseconds((prev) => prev - 1000);
    }, 1000);
    const { remHours, remSeconds, remDays, remMinutes } =
      getRemainingTime(milliseconds);

    let daysAsNumber = Math.trunc(remDays);
    if (daysAsNumber.toString().length < 2)
      daysAsNumber = daysAsNumber.toString().padStart(2, 0);

    let hoursAsNumber = remHours / remDays - 1;
    if (hoursAsNumber.toString().length < 2)
      hoursAsNumber = hoursAsNumber.toString().padStart(2, 0);

    let minutesAsNumber = Math.trunc(remMinutes % 60);
    if (minutesAsNumber.toString().length < 2)
      minutesAsNumber = minutesAsNumber.toString().padStart(2, 0);

    let secondsAsNumber = Math.trunc(remSeconds % 60);
    if (secondsAsNumber.toString().length < 2)
      secondsAsNumber = secondsAsNumber.toString().padStart(2, 0);

    setDays(daysAsNumber);
    sethours(hoursAsNumber);
    setMinutes(minutesAsNumber);
    setSeconds(secondsAsNumber);

    // setDays(Math.trunc(remDays));
    // sethours(remHours / remDays - 1);
    // setMinutes(Math.trunc(remMinutes % 60));
    // setSeconds(Math.trunc(remSeconds % 60));

    return () => clearInterval(timer);
  }, [milliseconds]);

  return { days, hours, minutes, seconds };
};
