import {
  Button,
  Checkbox,
  FormItem,
  FormLayout,
  Group,
  Panel,
  PanelHeader,
  Radio,
} from "@vkontakte/vkui";
import axios from "axios";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { config } from "../config";
import { StoreContext } from "../store/Store";

export const StartingQuizPanel = ({ setActivePanel, id }) => {
  const user = useContext(StoreContext).users;
  const navigate = useNavigate();
  let location = useLocation();

  const [back, setBack] = useState(0);
  const [neck, setNeck] = useState(0);
  const [shoulderGirdle, setShoulderGirdle] = useState(0);
  const [pelvis, setPelvis] = useState(0);
  const [flexibility, setFlexibility] = useState(0);
  const [breath, setBreath] = useState(0);
  const [relaxationTechniques, setRelaxationTechniques] = useState(0);
  const [invertedAsanas, setInvertedAsanas] = useState(0);
  const [invertedAsanasAccess, setInvertedAsanasAccess] = useState(true);
  const [invertedAsanasAccessNumber, setInvertedAsanasAccessNumber] =
    useState(1);
  const [invertedAsanasComplicatedNumber, setInvertedAsanasComplicatedNumber] =
    useState(0); //Default 0 !!!!!!!!
  const [invertedAsanasComplicated, setInvertedAsanasComplicated] = useState(0);
  const [breathPro, setBreathPro] = useState(true);
  const [breathProNumber, setBreathProNumber] = useState(1);
  const [trataka, setTrataka] = useState(true);
  const [tratakaNumber, setTratakaNumber] = useState(1);
  const [checkboxBack, setCheckboxBack] = useState("");
  const [checkboxEye, setCheckboxEye] = useState(false);
  const [updatedTest, setUpdatedTest] = useState(false);

  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [question5, setQuestion5] = useState(false);
  const [question6, setQuestion6] = useState(false);
  const [question7, setQuestion7] = useState(false);
  const [question8, setQuestion8] = useState(false);
  const [question9, setQuestion9] = useState(false);
  const [question10, setQuestion10] = useState(false);

  const questionsStatusArray = [
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    question9,
  ];

  const onCheckBoxBack1 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
      setNeck((prev) => prev + 1);
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
    } else {
      setBack((prev) => prev - 1);
      setNeck((prev) => prev - 1);
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
    }
  };

  const onCheckBoxBack2 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
    } else {
      setBack((prev) => prev - 1);
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
    }
  };

  const onCheckBoxBack3 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
    } else {
      setBack((prev) => prev - 1);
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
    }
  };

  const onCheckBoxBack4 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
      setNeck((prev) => prev + 1);
      setShoulderGirdle((prev) => prev + 1);
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
    } else {
      setBack((prev) => prev - 1);
      setNeck((prev) => prev - 1);
      setShoulderGirdle((prev) => prev - 1);
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
    }
  };

  const onCheckBoxBack5 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
      setPelvis((prev) => prev + 1);
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
    } else {
      setBack((prev) => prev - 1);
      setPelvis((prev) => prev - 1);
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
    }
  };

  const onCheckBoxBack6 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
      setShoulderGirdle((prev) => prev + 1);
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
    } else {
      setBack((prev) => prev - 1);
      setShoulderGirdle((prev) => prev - 1);
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
    }
  };

  const onCheckBoxBack7 = (e) => {
    if (e.target.checked) {
      setBack((prev) => prev + 1);
    } else {
      setBack((prev) => prev - 1);
    }
  };

  const onCheckBoxEye = (e) => {
    if (e.target.checked) {
      setInvertedAsanasComplicatedNumber((prev) => prev - 1);
      setTratakaNumber((prev) => prev - 1);
      setInvertedAsanasAccessNumber((prev) => prev - 1);
      setBreathProNumber((prev) => prev - 1);
    } else {
      setInvertedAsanasComplicatedNumber((prev) => prev + 1);
      setTratakaNumber((prev) => prev + 1);
      setInvertedAsanasAccessNumber((prev) => prev + 1);
      setBreathProNumber((prev) => prev + 1);
    }
  };

  const handleForm = async () => {
    // if (breathProNumber <= 0) {
    //   setBreathPro(false);
    // }
    // if (invertedAsanasAccessNumber <= 0) {
    //   setInvertedAsanasAccess(false);
    // }
    // if (invertedAsanasComplicatedNumber <= 0) {
    //   setInvertedAsanasComplicated(false);
    // }
    // if (tratakaNumber <= 0) {
    //   setTrataka(false);
    // }

    const startingQuizObject = {
      back,
      neck,
      shoulderGirdle,
      breathPro: breathProNumber <= 0 ? false : true,
      pelvis,
      flexibility,
      breath,
      invertedAsanas,
      invertedAsanasAccess: invertedAsanasAccessNumber <= 0 ? false : true,
      // invertedAsanasComplicated,
      trataka: tratakaNumber <= 0 ? false : true,
    };

    const booleanValues = Object.fromEntries(
      Object.entries(startingQuizObject).filter(
        (el) => el[1] === true || el[1] === false
      )
    );

    const numberValues = Object.fromEntries(
      Object.entries(startingQuizObject).filter(
        (el) =>
          el[1] !== false &&
          el[1] !== true &&
          el[1] >= 0 &&
          el[0] != "invertedAsanas"
      )
    );

    if (questionsStatusArray.some((el) => el === false)) {
      return console.log("Please fill all");
    }
    // navigate("/test-passed");
    // window.top.location.href = "https://vk.com/app8013570#/test-passed";

    const { data } = await axios.post(
      config.api.postStartingTestLink,
      { booleanValues, numberValues, vk_user_id: user.data.vk_user_id },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    window.location.reload();
    // navigate("/test-passed");

    // window.top.location.href = "https://vk.com/app8013570";
  };

  return (
    <Panel id={id}>
      <PanelHeader>Тест</PanelHeader>
      <FormLayout>
        <Group>
          <FormItem
            top="Есть ли у вас проблемы с позвоночником? (да / нет)"
            status={question1 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="back"
              value={back}
              onClick={() => {
                setCheckboxBack(true);
                setQuestion1(true);
              }}
            >
              да
            </Radio>
            <Radio
              name="back"
              value={back}
              onClick={() => {
                setCheckboxBack(false);
                setQuestion1(true);
              }}
            >
              нет
            </Radio>
          </FormItem>
          {checkboxBack && (
            <FormItem top="Дополнительно">
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack1(e)}
              >
                остеохондроз, грыжи, протрузии и т.п.
              </Checkbox>
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack2(e)}
              >
                сколиоз
              </Checkbox>
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack3(e)}
              >
                искривления позвоночника
              </Checkbox>
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack4(e)}
              >
                болит шея
              </Checkbox>
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack5(e)}
              >
                болит поясница
              </Checkbox>
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack6(e)}
              >
                сутулость
              </Checkbox>
              <Checkbox
                name="back"
                value={back}
                onClick={(e) => onCheckBoxBack7(e)}
              >
                свой вариант
              </Checkbox>
            </FormItem>
          )}
        </Group>

        <Group>
          <FormItem
            top="Есть ли у вас проблемы с давлением?"
            status={question2 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="pressure"
              value={breathPro}
              onClick={(e) => {
                setBreathPro(false);
                setInvertedAsanasAccess(false);
                setInvertedAsanasComplicated(false);
                setQuestion2(true);
              }}
            >
              да, бывает повышенное давление (в т.ч. артериальная гипертензия)
            </Radio>
            <Radio
              name="pressure"
              value={breathPro}
              onClick={(e) => {
                setBreathPro(false);
                setQuestion2(true);
              }}
            >
              да, пониженное давление
            </Radio>
            <Radio
              name="pressure"
              value={breathPro}
              onClick={(e) => {
                setBreathPro(true);
                setQuestion2(true);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Есть ли у вас инсульт в анамнезе?"
            bottom={"Пожалуйста, выберите вариант"}
            status={question3 ? "valid" : "error"}
          >
            <Radio
              name="stroke"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion3(true);
                  return;
                }
                setBreathPro((prev) => prev - 1);
                setInvertedAsanasAccessNumber((prev) => prev - 1);
                setInvertedAsanasComplicatedNumber((prev) => prev - 1);
                setQuestion3(true);
              }}
            >
              да
            </Radio>
            <Radio
              name="stroke"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion3(true);
                  return;
                }
                setBreathPro((prev) => prev + 1);
                setInvertedAsanasAccessNumber((prev) => prev + 1);
                setInvertedAsanasComplicatedNumber((prev) => prev + 1);
                setQuestion3(true);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Беспокоят ли вас головные боли?"
            status={question4 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="headache"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion4(true);
                  return;
                }
                setBack((prev) => prev + 1);
                setNeck((prev) => prev + 1);
                setShoulderGirdle((prev) => prev + 1);
              }}
            >
              да, в т.ч. мигрень
            </Radio>
            <Radio
              name="headache"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion4(true);
                  return;
                }
                setBack((prev) => prev - 1);
                setNeck((prev) => prev - 1);
                setShoulderGirdle((prev) => prev - 1);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Есть ли у вас бронхиальная астма?"
            status={question5 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="asthma"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion5(true);
                  return;
                }
                setBreath((prev) => prev + 1);
                setBack((prev) => prev + 1);
                setShoulderGirdle((prev) => prev + 1);
                setInvertedAsanas((prev) => prev + 1);
              }}
            >
              да
            </Radio>
            <Radio
              name="asthma"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion5(true);
                  return;
                }
                setBreath((prev) => prev - 1);
                setBack((prev) => prev - 1);
                setShoulderGirdle((prev) => prev - 1);
                setInvertedAsanas((prev) => prev - 1);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Есть ли у вас варикоз?"
            status={question6 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="varicose"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion6(true);
                  return;
                }
                setBreath((prev) => prev + 1);
                setPelvis((prev) => prev + 1);
                setInvertedAsanas((prev) => prev + 1);
              }}
            >
              да
            </Radio>
            <Radio
              name="varicose"
              value={breathPro}
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion6(true);
                  return;
                }
                setBreath((prev) => prev - 1);
                setPelvis((prev) => prev - 1);
                setInvertedAsanas((prev) => prev - 1);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Имеются ли у вас заболевания глаз? (да / нет)"
            status={question7 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="eye"
              value={breathPro}
              onClick={(e) => {
                setQuestion7(true);
                setCheckboxEye(true);
              }}
            >
              да
            </Radio>
            <Radio
              name="eye"
              value={breathPro}
              onClick={(e) => {
                setQuestion7(true);
                setCheckboxEye(false);
              }}
            >
              нет
            </Radio>
          </FormItem>
          {checkboxEye && (
            <FormItem top="Заболевания глаз">
              <Checkbox onClick={(e) => onCheckBoxEye(e)}>
                Отслоение сетчатки
              </Checkbox>
              <Checkbox onClick={(e) => onCheckBoxEye(e)}>Катаракта</Checkbox>
              <Checkbox onClick={(e) => onCheckBoxEye(e)}>Глаукома</Checkbox>
              <Checkbox>Ничего из вышеперечисленного</Checkbox>
            </FormItem>
          )}
        </Group>

        <Group>
          <FormItem
            top="Бывают ли у вас боли во время месячных?"
            status={question8 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="female1"
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion8(true);
                  return;
                }
                setInvertedAsanas((prev) => prev + 1);
                setBreath((prev) => prev + 1);
                setPelvis((prev) => prev + 1);
                setFlexibility((prev) => prev + 1);
              }}
            >
              да
            </Radio>
            <Radio
              name="female1"
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion8(true);
                  return;
                }
                setInvertedAsanas((prev) => prev - 1);
                setBreath((prev) => prev - 1);
                setPelvis((prev) => prev - 1);
                setFlexibility((prev) => prev - 1);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>

        <Group>
          <FormItem
            top="Есть ли у вас поликистоз яичников или миома матки?"
            status={question9 ? "valid" : "error"}
            bottom={"Пожалуйста, выберите вариант"}
          >
            <Radio
              name="female2"
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion9(true);
                  return;
                }
                setBreathPro((prev) => prev - 1);
              }}
            >
              да
            </Radio>
            <Radio
              name="female2"
              onClick={(e) => {
                if (e.target.checked) {
                  setQuestion9(true);
                  return;
                }
                setBreathPro((prev) => prev + 1);
              }}
            >
              нет
            </Radio>
          </FormItem>
        </Group>
        {updatedTest && (
          <Group>
            <FormItem
              top="Хотите ли вы практиковать усложненные варианты асан?"
              status={question10 ? "valid" : "error"}
              bottom={"Пожалуйста, выберите вариант"}
            >
              <Radio
                name="secondTest"
                onClick={(e) => {
                  if (e.target.checked) {
                    setQuestion10(true);
                    return;
                  }
                  setInvertedAsanasComplicated(true);
                }}
              >
                да
              </Radio>
              <Radio
                name="secondTest"
                onClick={(e) => {
                  if (e.target.checked) {
                    setQuestion10(true);
                    return;
                  }
                  setInvertedAsanasComplicated(false);
                }}
              >
                нет
              </Radio>
            </FormItem>
          </Group>
        )}
        <FormItem>
          <Button size="l" stretched onClick={handleForm}>
            Отправить
          </Button>
        </FormItem>
      </FormLayout>
    </Panel>
  );
};
