import { Panel, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";
import { ExerciseListElement } from "../components/ExerciseListElement";

export const ExerciseListPanel = ({
  setActivePanel,
  id,
  onHelp1,
  exercises_pool,
  setActiveExercise,
  user,
}) => {
  const goMain = () => {
    return setActivePanel("AccountPanel");
  };

  return (
    <Panel id="ExerciseListPanel">
      <PanelHeader left={<PanelHeaderBack onClick={goMain} />}>
        Все уроки
      </PanelHeader>

      {exercises_pool.map((el) => (
        <ExerciseListElement
          key={el?._id}
          exerciseTitle={el?.title}
          setActivePanel={setActivePanel}
          setActiveExercise={setActiveExercise}
          exerciseData={el}
          user={user}
        />
      ))}
    </Panel>
  );
};
