import {
  Button,
  Card,
  CardGrid,
  Div,
  Panel,
  PanelHeader,
  PanelHeaderBack,
} from "@vkontakte/vkui";
import axios from "axios";
import YouTube from "react-youtube";
import { config } from "../config";
import { videoToID } from "../utils/YoutubeLinkToID";

export const CurrentExercisePanel = ({ setActivePanel, data, user }) => {
  const { innerWidth: width, innerHeight: height } = window;
  const opts = {
    height: (width - 32) * 0.6,
    width: width - 32,
  };
  const onHomeWorkHandler = async () => {
    const lastPostUrl =
      // await axios.get("https://yoga.stn21f.ru/api/v1/last-post")
      (await axios.get(config.api.getLastPostUrl)).data.msg;
    window.top.location.href = lastPostUrl;
  };

  return (
    <Panel id="CurrentExercisePanel">
      <PanelHeader
        onClick={() => setActivePanel("AccountPanel")}
        left={<PanelHeaderBack />}
      >
        {data.title ? data.title : ""}
      </PanelHeader>

      <CardGrid size="l">
        <Card mode="shadow">
          <YouTube videoId={videoToID(data.link)} opts={opts} />
        </Card>
        {user.data.review_available && (
          <Div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button size="l" onClick={onHomeWorkHandler}>
              Домашнее задание
            </Button>
          </Div>
        )}
      </CardGrid>
    </Panel>
  );
};
