import { AppRoot, Spinner } from "@vkontakte/vkui";
import "@vkontakte/vkui/dist/vkui.css";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import { config } from "./config";
import { StoreContext } from "./store/Store";
import { AccountView } from "./views/AccountView/AccountView";
import { AdminView } from "./views/AdminView/AdminView";
import { PaymentView } from "./views/PaymentView/PaymentView";
import { QuizPassedView } from "./views/QuizPassedView/QuizPassedView";
import { StartingQuizView } from "./views/StartingQuizView/StartingQuizView";

function App() {
  const user = useContext(StoreContext).users;
  const exercises_pool = user.data.exercises_pool;
  const current_exercise = user.data.current_exercise;
  const first_name = user.data.first_name;
  return (
    <AppRoot>
      {!first_name && <Spinner size="large" />}
      {first_name && user && (
        <Routes>
          <Route path="/home" element={<Navigate to="/account" />} />
          <Route
            path="/account"
            element={
              <AccountView
                exercises_pool={exercises_pool}
                current_exercise={current_exercise}
                user={user}
              />
            }
          />
          <Route
            path="/"
            element={
              ((user.data.active === false || !user.data.vk_user_id) && (
                <PaymentView />
              )) ||
              (user.data.active === true &&
                user.data.start_test_passed === false && (
                  <StartingQuizView />
                )) ||
              (user.data.active === true &&
                user.data.start_test_passed === true && (
                  <AccountView
                    exercises_pool={exercises_pool}
                    current_exercise={current_exercise}
                    user={user}
                  />
                ))
            }
          />
          <Route path="/test-passed" element={<QuizPassedView />} />
          <Route path="/payment" element={<PaymentView />} />
          <Route path="/starting-quiz" element={<StartingQuizView />} />
          {config.APP_ADMINS_ARRAY.includes(user.data.vk_user_id) ? (
            <Route path="admin=:id" element={<AdminView />} />
          ) : (
            <Route path="admin=:id" element={<Navigate replace to="/" />} />
          )}
        </Routes>
      )}
    </AppRoot>
  );
}

export default App;
